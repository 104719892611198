<template>
    <div class="modal" id="appInfoModal" tabindex="-1" data-backdrop="false">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Přehled novinek za rok 2024</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <template v-if="localization == 'cs'">
                        <h5>1.	Kalkulačka účelně vynaložených nákladů</h5>
                        <p>
                            Nevíte, jestli a případně v jaké výši Váš klient bude platit účelně vynaložené náklady podle pravidel od září 2024? Nevíte, jakou pro výpočet vybrat referenční úrokovou sazbu? Nevadí, máme pro Vás jednoduchou kalkulačku účelně vynaložených nákladů, kde stačí zadat vstupní údaje ke stávající hypotéce a kalkulačka již vše vypočte včetně natažení správné referenční úrokové sazby vyhlašované ČNB.
                        </p>
                        <h5>2.	Modul Refinancování</h5>
                        <p>
                            Potřebujete klientovi dát jednoduše a rychle argumenty, že se mu refinancování vyplatí? Nebo naopak nevyplatí? Využijte náš modul Refinancování, kde můžete na pár kliků porovnat podmínky stávající a nové banky, porovnat si splátkové kalendáře, zohlednit náklady spojené s první nebo druhou variantou včetně nákladů spojených s refinancováním a výpočtu účelně vynaložených nákladů.
                        </p>
                        <h5>3.	Porovnání více variant hypotéky najednou</h5>
                        <p>
                            Nebaví Vás klientovi postupně počítat x různých variant hypotéky? Pokud v propočtu hypotečních úvěrů použijete tlačítko „Přidat variantu“, můžete propočítat x různých variant najednou. Pro každou variantu a banku pak můžete přes tlačítko „Slevy a poplatky“ individuálně upravovat sazby a náklady spojené s hypotékou.
                        </p>
                        <h5>4.	Metodický filtr v propočtu sazeb hypotečních úvěrů</h5>
                        <p>
                            Nechcete vytvářet nabídku pro klienta na jednom místě a na jiném místě pak ověřovat informace z metodiky? Využijte náš metodický filtr v propočtu sazeb, kde si vyberete, co Vás z metodiky v daném případě zajímá, a naše aplikace Vám pak setřídí banky také podle toho, jestli daná banka zvolená metodická kritéria umí, neumí nebo posuzují individuálně. Jednoduchým proklikem pak můžete zjistit také detailní podmínky z metodiky. K tomu všemu stačí použít tlačítko „Metodicky filtr“ v propočtu sazeb hypotečních úvěrů.
                        </p>
                        <h5>5.	Menu</h5>
                        <p>
                            Říkáte si, že je složité se v aplikaci pohybovat a nevíte, jak se jednoduše dostat z jedné části do druhé? Připravili jsme pro Vás menu, které najdete v kterémkoliv kroku aplikace v pravém horním rohu (znak tří svislých čar) a pomocí kterého se můžete jednoduše a rychle dostat z jednoho místa aplikace do jakéhokoliv jiného místa napříč všemi moduly.
                        </p>
                        <h5>6.	Nový splátkový kalendář</h5>
                        <p>
                            Máte zvídavého klienta, který chce do detailu vědět, kolik bude platit na jistině, na úrocích, jak se změní vývoj úvěru, když provede mimořádnou splátku a nechá si zkrátit splatnost nebo snížit plátku, co se stane, když po konci fixace se změní úroková sazba atd.? Pro tyhle situace jsme pro Vás připravili nový splátkový kalendář, který tohle všechno zvládne.
                        </p>
                        <h5>7.	Ukládání</h5>
                        <p>
                            Děláte hodně kalkulací a potřebujete si k nim proto přidat poznámku? Potřebujete na první dobrou poznat, jestli je uložená modelace z propočet sazeb, z modulu refinancování nebo jde o uložený splátkový kalendář? Potřebujete rychlé filtrování? Právě z těchto důvodů jsme předělali přehled uložených kalkulací, aby se Vám lépe pracovalo. A hlavně jsme zrychlili jejich načítání 😊
                        </p>
                        <p>
                            A co v roce 2025? Nasloucháme tomu, co od aplikace očekáváte, a na základě Vašich zpětných vazeb připravujeme další novinky. Určitě se máte na co těšit 😊
                        </p>
                    </template>
                    <template v-if="localization == 'sk'">

                   </template>
                </div>
                <div class="modal-footer">
                    <button type="button"
                            class="btn btn-primary"
                            @click="seen">
                        Příště nezobrazovat
                    </button>
                    <button type="button"
                            class="btn btn-primary"
                            data-dismiss="modal">
                        Zobrazit příště
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'v-app-info-modal',
        methods: {
            seen() {
                this.$cookies.set("appInfoSeen", true);
                $('#appInfoModal').modal('hide');
            }
        },
        props: {
            localization: String
        }
    }
</script>

<style scoped>
    h5 {
        margin: 30px 0 7px 30px;
    }
</style>

