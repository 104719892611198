<template>
    <div>
        <div v-if="resultItems.length <= 0 && calcInProgress" class="data-loading">
            <i class="fas fa-spinner fa-spin"></i> {{ $t('credit.resultTable.dataLoading') }}
        </div>
        <div v-if="resultItems.length <= 0 && !calcInProgress" class="no-data">
            {{ $t('credit.resultTable.noResults') }}
        </div>
        <div v-if="resultItems.length > 0 && windowWidth >= 1200">
            <template v-if="isSk && !isSkUni">
                <table class="table table-hypo">
                    <thead>
                        <tr>
                            <th class="bankNameTableCell">{{ $t('credit.resultTable.bank') }}*</th>
                            <th class="text-right">{{ $t('credit.resultTable.maximumAmount') }}</th>
                            <th class="text-right">{{ $t('credit.resultTable.maturity') }}</th>
                            <th class="text-right">{{ $t('credit.resultTable.payment') }}</th>
                            <th v-bTooltip="$t('credit.resultTable.dtiTitle')" class="text-center">{{ $t('credit.resultTable.dti') }}</th>
                            <th v-bTooltip="$t('credit.resultTable.dstiTitle')" class="text-center">{{ $t('credit.resultTable.dsti') }}</th>
                            <th class="text-right">{{ $t('credit.resultTable.totalIncome') }}</th>
                            <th class="text-center">{{ $t('credit.resultTable.countedIncomed') }}</th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody v-for="item in skSuperBanks" :key="item.BankCode">
                        <v-result-table-item-sk v-if="item.CalcState === 'CompletedWithData'"
                                   :showLoanScheduleLink="showLoanScheduleLink"
                                   :item="item"
                                   :calcInProgress="calcInProgress"
                                   :loanParams="loanParams"
                                   :limitlessResult="withoutDtiDstiLimits"
                                   :dorisMortgagesAllowed="dorisMortgagesAllowed"
                                   :dorisLinkTarget="dorisLinkTarget">
                        </v-result-table-item-sk>
                        <v-result-table-item-loading v-if="item.CalcState === 'Loading' || item.CalcState === 'Placeholder'"
                                                     :calcInProgress="calcInProgress"
                                                     :item="item">
                        </v-result-table-item-loading>
                        <v-result-table-item-error v-if="item.CalcState === 'CompletedWithoutData'"
                                                   :item="item">
                        </v-result-table-item-error>
                        <v-result-table-item-messages :uiDebug="uiDebug"
                                                      v-if="item.Messages.length > 0 || (uiDebug && item.MessagesDebug.length > 0)"
                                                      :item="item"
                                                      :limitlessResult="withoutDtiDstiLimits">
                        </v-result-table-item-messages>
                    </tbody>
                </table>
                <table class="table table-hypo">
                    <thead>
                        <tr>
                            <th class="bankNameTableCell">{{ $t('credit.resultTable.bank') }}**</th>
                            <th class="text-right">{{ $t('credit.resultTable.maximumAmount') }}</th>
                            <th class="text-right">{{ $t('credit.resultTable.maturity') }}</th>
                            <th class="text-right">{{ $t('credit.resultTable.payment') }}</th>
                            <th v-bTooltip="$t('credit.resultTable.dtiTitle')" class="text-center">{{ $t('credit.resultTable.dti') }}</th>
                            <th v-bTooltip="$t('credit.resultTable.dstiTitle')" class="text-center">{{ $t('credit.resultTable.dsti') }}</th>
                            <th class="text-right">{{ $t('credit.resultTable.totalIncome') }}</th>
                            <th class="text-center">{{ $t('credit.resultTable.countedIncomed') }}</th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody v-for="item in skOtherBanks" :key="item.BankCode">
                        <v-result-table-item-sk v-if="item.CalcState === 'CompletedWithData'"
                                   :showLoanScheduleLink="showLoanScheduleLink"
                                   :item="item"
                                   :calcInProgress="calcInProgress"
                                   :loanParams="loanParams"
                                   :limitlessResult="withoutDtiDstiLimits"
                                   :dorisMortgagesAllowed="dorisMortgagesAllowed"
                                   :dorisLinkTarget="dorisLinkTarget">
                        </v-result-table-item-sk>
                        <v-result-table-item-loading v-if="item.CalcState === 'Loading' || item.CalcState === 'Placeholder'"
                                                     :calcInProgress="calcInProgress"
                                                     :item="item">
                        </v-result-table-item-loading>
                        <v-result-table-item-error v-if="item.CalcState === 'CompletedWithoutData'"
                                                   :item="item">
                        </v-result-table-item-error>
                        <v-result-table-item-messages :uiDebug="uiDebug"
                                                      v-if="item.Messages.length > 0 || (uiDebug && item.MessagesDebug.length > 0)"
                                                      :item="item"
                                                      :limitlessResult="withoutDtiDstiLimits">
                        </v-result-table-item-messages>
                    </tbody>
                </table>
                <div id="credit-worthiness-result-action-buttons">
                    <div style="width: 100%; text-align: right; font-size: 13px; padding-bottom: 10px;">
                        {{ $t('credit.resultTable.legalNotice') }}
                    </div>
                    <div>
                        <span>* Pre tieto banky je použitý ich vlastný prepočet bonity.</span><br />
                        <span>** Pre ostatné banky je použitý všeobecný prepočet bonity v súlade s nástrojmi finančnej stability Národnej banky Slovenska uvedenými na <a href="https://nbs.sk/financna-stabilita/nastroje-fs/">Nástroje finančnej stability - Národná banka Slovenska (nbs.sk)</a>.</span>
                    </div>
                </div>
            </template>
            <template v-else>
                <table class="table table-hypo">
                    <thead>
                        <tr>
                            <th class="bankNameTableCell">{{ $t('credit.resultTable.bank') }}</th>
                            <th class="text-right">{{ $t('credit.resultTable.maximumAmount') }}</th>
                            <th class="text-right">{{ $t('credit.resultTable.maturity') }}</th>
                            <th class="text-right">{{ $t('credit.resultTable.payment') }}</th>
                            <th v-bTooltip="$t('credit.resultTable.dtiTitle')" class="text-center">{{ $t('credit.resultTable.dti') }}</th>
                            <th v-bTooltip="$t('credit.resultTable.dstiTitle')" class="text-center">{{ $t('credit.resultTable.dsti') }}</th>
                            <th class="text-right">{{ $t('credit.resultTable.totalIncome') }}</th>
                            <th class="text-center">{{ $t('credit.resultTable.countedIncomed') }}</th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody v-for="item in resultItems" :key="item.BankCode">
                        <component v-if="item.CalcState === 'CompletedWithData'"
                                   v-bind:is="resultComponent"
                                   :showLoanScheduleLink="showLoanScheduleLink"
                                   :item="item"
                                   :calcInProgress="calcInProgress"
                                   :loanParams="loanParams"
                                   :limitlessResult="withoutDtiDstiLimits"
                                   :dorisMortgagesAllowed="dorisMortgagesAllowed"
                                   :dorisLinkTarget="dorisLinkTarget">
                        </component>
                        <v-result-table-item-loading v-if="item.CalcState === 'Loading' || item.CalcState === 'Placeholder'"
                                                     :calcInProgress="calcInProgress"
                                                     :item="item">
                        </v-result-table-item-loading>
                        <v-result-table-item-error v-if="item.CalcState === 'CompletedWithoutData'"
                                                   :item="item">
                        </v-result-table-item-error>
                        <v-result-table-item-messages :uiDebug="uiDebug"
                                                      v-if="item.Messages.length > 0 || (uiDebug && item.MessagesDebug.length > 0)"
                                                      :item="item"
                                                      :limitlessResult="withoutDtiDstiLimits">
                        </v-result-table-item-messages>
                    </tbody>
                </table>
                <div id="credit-worthiness-result-action-buttons">
                    <div class="pull-right">
                        {{ $t('credit.resultTable.legalNotice') }}
                    </div>
                </div>
            </template>
        </div>
        <div v-if="resultItems.length > 0 && windowWidth < 1200">
            <div v-for="item in resultItems" :key="item.BankCode" class="offer-group">
                <v-result-table-item-mobile :showLoanScheduleLink="showLoanScheduleLink"
                                            :item="item"
                                            :calcInProgress="calcInProgress"
                                            :loanParams="loanParams"
                                            :limitlessResult="withoutDtiDstiLimits"
                                            :dorisMortgagesAllowed="dorisMortgagesAllowed"
                                            :dorisLinkTarget="dorisLinkTarget">
                </v-result-table-item-mobile>
            </div>
            <div id="credit-worthiness-result-action-buttons">
                <div class="pull-right">
                    {{ $t('credit.resultTable.legalNotice') }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import ResultTableItemCz from './ResultTableItemCz.vue';
    import ResultTableItemSk from './ResultTableItemSk.vue';
    import ResultTableItemSkUni from './ResultTableItemSkUni.vue';
    import ResultTableItemMobile from './ResultTableItemMobile.vue';
    import ResultTableItemLoading from './ResultTableItemLoading.vue';
    import ResultTableItemMessages from './ResultTableItemMessages.vue';
    import ResultTableItemError from './ResultTableItemError.vue';

    export default {
        name: 'v-result-table',
        data() {
            return {
                calculationStorageAlowed: false,
                windowWidth: 0
            }
        },
        props: {
            resultItems: Array,
            calcInProgress: Boolean,
            loanParams: Object,
            showLoanScheduleLink: Boolean,
            uiDebug: Boolean,
            printButtonVisible: Boolean,
            printToUrlButtonVisible: Boolean,
            printToUrlDataExtendedButtonVisible: Boolean,
            showReportCalculationError: Boolean,
            withoutDtiDstiLimits: Boolean,
            dorisMortgagesAllowed: Boolean,
            dorisLinkTarget: String
        },
        methods: {
            printRequest: function (val) {
                this.$emit('printRequest', val);
            },
            printToUrlRequest: function () {
                this.$emit('printToUrlRequest');
            },
            printToUrlDataExtendedRequest: function () {
                this.$emit('printToUrlDataExtendedRequest');
            },
            toggleLimitlessResultVisibility: function (event) {
                this.axios.post("/WebServices/StoredCalculations.asmx/LimitlessResultVisibility", {
                    headers: { Accept: 'application/json' },
                    show: event.srcElement.checked
                })
                    .then(response => {
                        var parsedResponse = JSON.parse(response.data.d);
                        if (parsedResponse.ResponseStatusCode != 200) {
                            alert(parsedResponse.Data);
                        }
                    })
                    .catch(error => {
                        // eslint-disable-next-line
                        alert('Error ' + error.response.status + ' - ' + $t('error.couldNotConnectToBackend'));
                    });
            },
            windowResized() {
                this.windowWidth = window.innerWidth;
            }
        },
        computed: {
            resultComponent: function () {
                if (this.isSk) return 'v-result-table-item-sk-uni';
                return 'v-result-table-item-cz';
            },
            isSk: function () {
                return this.resultItems[0] && this.resultItems[0].BankCode.substr(0, 3) === 'SK_'
            },
            isSkUni: function () {
                return this.resultItems.some(d => d.BankCode == 'SK_UNI');
            },
            skSuperBanks: function () {
                return this.resultItems.filter((d) => d.BankCode === 'SK_UNI' || d.BankCode === 'SK_UCB' || d.BankCode === 'SK_MB' || d.BankCode === 'SK_CSOB');
            },
            skOtherBanks: function () {
                return this.resultItems.filter((d) => d.BankCode != 'SK_UNI' && d.BankCode != 'SK_UCB' && d.BankCode != 'SK_MB' && d.BankCode != 'SK_CSOB');
            }
        },
        components: {
            'v-result-table-item-cz': ResultTableItemCz,
            'v-result-table-item-sk': ResultTableItemSk,
            'v-result-table-item-sk-uni': ResultTableItemSkUni,
            'v-result-table-item-mobile': ResultTableItemMobile,
            'v-result-table-item-loading': ResultTableItemLoading,
            'v-result-table-item-messages': ResultTableItemMessages,
            'v-result-table-item-error': ResultTableItemError
        },
        mounted() {
            this.windowWidth = window.innerWidth;
            window.addEventListener("resize", this.windowResized);

            this.axios.post("/WebServices/StoredCalculations.asmx/CalculationStorageAlowed", {
                headers: { Accept: 'application/json' }
            })
                .then(response => {
                    var parsedResponse = JSON.parse(response.data.d);

                    if (parsedResponse.ResponseStatusCode == 200) {
                        this.calculationStorageAlowed = parsedResponse.Data;
                    }
                });
        }
    }

</script>

<style scoped>
    .check-withoutLimits {
        padding-top: 5px;
        text-align: center;
    }
</style>
