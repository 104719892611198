<template>
    <tr class="active resultItemRow">
        <td class="lh18">
            <span v-if="item.BankCode === 'SK_UNI'" class="uni-logo-label" @click="gtmTrackClickUI('Detail partnera', {partner: item.BankCode});">
                Univerzálna bonita klienta
            </span>
            <a v-else target="_blank" :href="'/Poskytovatel/' + item.BankCode"
               @click="gtmTrackClickUI('Detail partnera', {partner: item.BankCode});">
                <div class="smallImgWrapper">
                    <img v-bTooltip="item.ProductName" :src="'/ImageProxy.aspx?f=' + item.BankCode + '-big.png'" />
                </div>
            </a>
        </td>
        <template v-if="this.item.MaxAmount > 0">
            <td class="text-right">
                <b>
                    <vue-numeric :currency="$t('global.currencySymbol')" currency-symbol-position="suffix" separator="space" read-only="true" v-model="itemMaxAmount"></vue-numeric>
                </b>
            </td>

            <td class="text-right" v-if="itemMaturityMonths == 0">
                <vue-numeric :currency="$t('global.years')" v-if="item.Maturity > 0" currency-symbol-position="suffix" separator="space" read-only="true" v-model="itemMaturityYears"></vue-numeric>
            </td>
            <td v-else class="bonitaMaturityColumn text-right">
                <vue-numeric :currency="$t('global.years')" v-if="item.Maturity > 0" currency-symbol-position="suffix" separator="space" read-only="true" v-model="itemMaturityYears"></vue-numeric><br />
                <vue-numeric :currency="$t('global.month')" v-if="item.Maturity > 0 && itemMaturityMonths == 1" currency-symbol-position="suffix" separator="space" read-only="true" v-model="itemMaturityMonths"></vue-numeric>
                <vue-numeric :currency="$t('global.months2')" v-if="item.Maturity > 0 && itemMaturityMonths >= 2 && itemMaturityMonths < 5" currency-symbol-position="suffix" separator="space" read-only="true" v-model="itemMaturityMonths"></vue-numeric>
                <vue-numeric :currency="$t('global.months')" v-if="item.Maturity > 0 && itemMaturityMonths >= 5" currency-symbol-position="suffix" separator="space" read-only="true" v-model="itemMaturityMonths"></vue-numeric>
            </td>
            <td class="text-right">
                <vue-numeric :currency="$t('global.currencySymbol')" currency-symbol-position="suffix" separator="space" read-only="true" v-model="itemInstallment"></vue-numeric>
            </td>

            <td class="text-center">
                <vue-numeric decimal-separator="," precision="2" read-only="true" v-model="itemDti"></vue-numeric>
            </td>
            <td class="text-center">
                <vue-numeric decimal-separator="," precision="2" read-only="true" v-model="itemDsti" currency="%" currency-symbol-position="suffix"></vue-numeric>
            </td>

            <td class="text-right">
                <vue-numeric :currency="$t('global.currencySymbol')" currency-symbol-position="suffix" separator="space" read-only="true" v-model="item.AppliedIncomeTotal"></vue-numeric>
            </td>
        </template>
        <template v-else>
            <td class="text-center" colspan="6">
                <span>{{ $t('credit.resultTableItem.loanCant') }}</span>
            </td>
        </template>
                
        <td class="text-center">
            <span class="income-icons" style="font-size: 17px;">
                <img :class="{'gray': !item.AppliedEmployeeIncome}" src="@/assets/icons/prijmy_zavisla_cinnost.svg" v-bTooltip="item.AppliedEmployeeIncome ? $t('credit.resultTableItem.AppliedEmployeeIncome') + item.AppliedEmployeeIncome : $t('credit.resultTableItem.AppliedEmployeeIncome')" @mouseleave="hideAllTooltips" />
                <img :class="{'gray': !item.AppliedBusinessIncome}" src="@/assets/icons/prijmy_podnikani.svg" v-bTooltip="item.AppliedBusinessIncome ? $t('credit.resultTableItem.AppliedBusinessIncome') + item.AppliedBusinessIncome : $t('credit.resultTableItem.AppliedBusinessIncome')" @mouseleave="hideAllTooltips" />
                <img :class="{'gray': !item.AppliedBusinessIncomeDph}" src="@/assets/icons/prijmy_obrat.svg" v-bTooltip="item.AppliedBusinessIncomeDph ? $t('credit.resultTableItem.AppliedBusinessIncomeDph') + item.AppliedBusinessIncomeDph : $t('credit.resultTableItem.AppliedBusinessIncomeDph')" @mouseleave="hideAllTooltips" />
                <img :class="{'gray': !item.AppliedBusinessLegalEntityIncome}" src="@/assets/icons/prijmy_pravnicke_osoby.svg" v-bTooltip="item.AppliedBusinessLegalEntityIncome ? $t('credit.resultTableItem.AppliedBusinessLegalEntityIncome') + item.AppliedBusinessLegalEntityIncome : $t('credit.resultTableItem.AppliedBusinessLegalEntityIncome')" @mouseleave="hideAllTooltips" />
                <img :class="{'gray': !item.AppliedLeaseIncome}" src="@/assets/icons/prijmy_pronajem.svg" v-bTooltip="item.AppliedLeaseIncome ? $t('credit.resultTableItem.AppliedLeaseIncome') + item.AppliedLeaseIncome : $t('credit.resultTableItem.AppliedLeaseIncome')" @mouseleave="hideAllTooltips" />
                <img :class="{'gray': !item.AppliedOtherIncome}" src="@/assets/icons/prijmy_ostatni.svg" v-bTooltip="item.AppliedOtherIncome ? $t('credit.resultTableItem.AppliedOtherIncome') + item.AppliedOtherIncome : $t('credit.resultTableItem.AppliedOtherIncome')" @mouseleave="hideAllTooltips" />
            </span>
        </td>

        <td class="text-right">
            <button type="button"
                    class="btn btn-default-gray"
                    @click="linkToChart"
                    v-bTooltip="$t('credit.resultTableItem.gotoPaymentChart')">
                <img class="chart left" src="@/assets/icons/splatky-graf.svg" /> {{ $t('credit.resultTable.buttons.chart') }}
            </button>
        </td>
        <td>
            <a href="item.BankOnlineContractLink"
               @click="gtmTrackClickUI('Sjednání', {partner: item.BankCode});"
               v-if="showOnlineContractLink"
               class="btn btn-primary btn-arrange"
               target="_blank"
               v-bTooltip="$t('credit.resultTableItem.gotoBankOnlineContractSite') +' ' + item.BankCode">{{ $t('credit.resultTable.buttons.arrange') }}</a>
        </td>
    </tr>
</template>

<script>
    export default {
        name: 'v-result-table-item',
        props: {
            item: Object,
            calcInProgress: Boolean,
            loanParams: Object,
            showLoanScheduleLink: Boolean,
            limitlessResult: Boolean,
            dorisMortgagesAllowed: Boolean,
            dorisLinkTarget: String
        },
        computed: {
            calendarHrefLink: function () {
                return '/GuidRedirect.aspx?module=payments&params=' + encodeURIComponent(this.calendarParams);
            },
            refinanceHrefLink: function () {
                return '/GuidRedirect.aspx?module=refinance&params=' + encodeURIComponent(this.calendarParams);
            },
            calendarParams: function () {
                return '?product=' + this.item.BankCode
                    + '&amount=' + this.loanParams.loanAmount
                    + '&rate=' + this.loanParams.rate / 100
                    + '&maturity=' + this.item.Maturity
                    + '&fixation=' + this.loanParams.fixation
                    ;
            },
            showOnlineContractLink: function () {
                return this.item.BankOnlineContractLink != null && this.item.BankOnlineContractLink != "" && this.item.ShowOnlineContractLinkInCreditWorthiness;
            },
            itemDsti: function () {
                return this.limitlessResult && this.item.LimitlessResult.MaxAmount > 0 ? this.item.LimitlessResult.Dsti * 100 : this.item.Dsti * 100;
            },
            itemDti: function () {
                return this.limitlessResult && this.item.LimitlessResult.MaxAmount > 0 ? this.item.LimitlessResult.Dti : this.item.Dti;
            },
            itemMaxAmount: function () {
                return this.limitlessResult && this.item.LimitlessResult.MaxAmount > 0 ? this.item.LimitlessResult.MaxAmount : this.item.MaxAmount;
            },
            itemInstallment: function () {
                return this.limitlessResult && this.item.LimitlessResult.MaxAmount > 0 ? this.item.LimitlessResult.Installment : this.item.Installment;
            },
            itemMaturityYears() {
                return Math.floor(parseInt(this.item.Maturity / 12));
            },
            itemMaturityMonths() {
                return parseInt(this.item.Maturity) % 12;
            },
        },
        methods: {
            linkToChart() {
                this.gtmTrackClickUI('Splátkový kalendář', { partner: this.item.BankCode });
                window.open(this.calendarHrefLink, '_blank');
            },
            hideAllTooltips() {
                $(".tooltip").tooltip("hide");
            }
        }
    }
</script>

<style scoped>
    .income-icons > img {
        width: 20px;
        margin: 2px;
        filter: invert(37%) sepia(29%) saturate(2435%) hue-rotate(185deg) brightness(99%) contrast(88%);
    }

        .income-icons > img.gray {
            filter: invert(92%) sepia(0%) saturate(18%) hue-rotate(243deg) brightness(84%) contrast(91%) !important;
        }

    span.income-icons > i {
        padding-right: 5px;
    }

    span.badgeOverenoOnline {
        text-transform: uppercase;
        background-color: rgba(232, 242, 255, 1);
        color: #000;
        padding: 5px 10px;
        font-weight: 600;
    }

    tr.textGreenColor {
        color: LimeGreen !important;
        font-weight: bold;
    }

    span#linkToBankOnlinePortal {
        padding-right: 10px;
    }

    .fa-signature {
        color: #fff;
        background-color: #5cb85c;
        border-color: #4cae4c;
        padding: 3px;
        border-radius: 3px;
    }

    .bonitaMaturityColumn {
        padding: 1px 8px;
    }

    tr {
        white-space: nowrap;
    }
</style>

<style>
    .lh18 {
        line-height: 1.8 !important;
    }
</style>